        :root {
                --cl1: #ffffff;
                --cl2: #2F2E42;
                --cl3: #4337ca;
                --cl4: #ef4770;
                --cl5: #f4f4f4;
                --cl6: #b2b3ba;
                --cl7: #e9ecef;
                --cl8: #fca311;
                --cl9: #f5f4f4;
                --cl10: #ffffff;
        }

        .hover-cl1:hover,.cl1{color:#ffffff}.bg1,.hover-bg1:hover{background-color:#ffffff}
        .hover-cl2:hover,.cl2{color:#2F2E42}.bg2,.hover-bg2:hover{background-color:#2F2E42}
        .hover-cl3:hover,.cl3{color:#4337ca}.bg3,.hover-bg3:hover{background-color:#4337ca}
        .hover-cl4:hover,.cl4{color:#ef4770}.bg4,.hover-bg4:hover{background-color:#ef4770}
        .hover-cl5:hover,.cl5{color:#f4f4f4}.bg5,.hover-bg5:hover{background-color:#f4f4f4}
        .hover-cl6:hover,.cl6{color:#b2b3ba}.bg6,.hover-bg6:hover{background-color:#b2b3ba}
        .hover-cl7:hover,.cl7{color:#e9ecef}.bg7,.hover-bg7:hover{background-color:#e9ecef}
        .hover-cl8:hover,.cl8{color:#fca311}.bg8,.hover-bg8:hover{background-color:#fca311}
        .hover-cl9:hover,.cl9{color:#f5f4f4}.bg9,.hover-bg9:hover{background-color:#f5f4f4}
        .hover-cl10:hover,.cl10{color:#ffffff}.bg10,.hover-bg10:hover{background-color:#ffffff}

        .bgt,hover-bgt:hover{background-color:transparent}
        .bgg{background: linear-gradient(90deg, #2F2E42, #ef4770)}
        .bg2-light{background-color:#2F2E4240}
        .bg3-light{background-color:#4337ca40}
        .bg4-light{background-color:#ef477040}

        .fill-cl1{fill:#ffffff}
        .fill-cl2{fill:#2F2E42}
        .fill-cl3{fill:#4337ca}
        .fill-cl4{fill:#ef4770}
        .fill-cl5{fill:#f4f4f4}

        .bgg1{background: linear-gradient(180deg, rgba(53, 84, 209, 0.1) 0%, rgba(196, 196, 196, 0) 100%);}

        .brc0{border-width: 0;}
        .brc1solid{border-width: 1px;border-style: solid;}
        .brc2solid{border-width: 2px;border-style: solid;}
        .brc3solid{border-width: 3px;border-style: solid;}
        .brc3solidBottom{border-bottom-width: 3px;border-bottom-style: solid;}
        
        .brc1Right{border-right-width: 1px;border-right-style: solid;}
        .brc1Top{border-top-width: 1px;border-top-style: solid;}

        .hover-brc1:hover,.brc1{border-color:#ffffff}
        .hover-brc2:hover,.brc2{border-color:#2F2E42}
        .hover-brc3:hover,.brc3{border-color:#4337ca}
        .hover-brc4:hover,.brc4{border-color:#ef4770}
        .hover-brc5:hover,.brc5{border-color:#f4f4f4}
        .hover-brc6:hover,.brc6{border-color:#b2b3ba}
        .hover-brc7:hover,.brc7{border-color:#e9ecef}
        .hover-brc8:hover,.brc8{border-color:#fca311}
        .hover-brc9:hover,.brc9{border-color:#f5f4f4}
        .hover-brc10:hover,.brc10{border-color:#ffffff}

        .hover-brc3-Bottom:hover,.brc2-bottom{border-bottom-color:#2F2E42}


        .flex{display:flex;justify-content: space-between}
        .flex-end{display:flex;justify-content: flex-end}
        .flex-start{display:flex;justify-content: flex-start}
        .flex-wrap{display:flex;flex-wrap: wrap}

        .flex-reverse{flex-direction: row-reverse}
        .flex-col{flex-direction: column}
        .flex-row{flex-direction: row}

        .grid1{display:grid;grid-template-columns: repeat(1, calc( 100% ) [col-start])}
        .grid2{display:grid;grid-template-columns: repeat(2, calc( 50% - 15px ) [col-start]);grid-gap:20px}
        .grid3{display:grid;grid-template-columns: repeat(3, calc( calc(100% / 3) - 15px )[col-start]);grid-gap:20px}
        .grid4{display:grid;grid-template-columns: repeat(4, calc( 25% - 15px ) [col-start]);grid-gap:20px}
        .grid5{display:grid;grid-template-columns: repeat(5, calc( 20% - 15px ) [col-start]);grid-gap:20px}
        .grid6{display:grid;grid-template-columns: repeat(6, calc( calc(100% / 6) - 15px ) [col-start]);grid-gap:20px}

        .pos-r{position:relative}
        .pos-a{position:ansolute}

        .fz07{font-size: .7em}
        .fz08{font-size: .8em}
        .fz09{font-size: .9em}
        .fz11{font-size: 1.1em}
        .fz12{font-size: 1.2em}
        .fz13{font-size: 1.3em}
        .fz15{font-size: 1.5em}
        .fz18{font-size: 1.8em}
        .fz2{font-size: 2em}
        .fz3{font-size: 3em}
        .fz4{font-size: 4em}
        .fz5{font-size: 5em}

        .textUp{text-transform: uppercase}

        .fw3{font-weight:300}
        .fw4{font-weight:400}
        .fw5{font-weight:500}
        .fw6{font-weight:600}
        .fw7{font-weight:700}

        .p0{padding: 0}
        .p5{padding: 5px}
        .p8{padding: 8px}
        .p10{padding: 10px}
        .p15{padding: 15px}
        .p20{padding: 20px}
        .p30{padding: 30px}
        .p40{padding: 40px}
        .p50{padding: 50px}
        .p60{padding: 60px}
        .p70{padding: 70px}
        .p80{padding: 80px}

        .pt10{padding-top: 10px}
        .pt20{padding-top: 20px}
        .pt30{padding-top: 30px}
        .pt40{padding-top: 40px}
        .pt50{padding-top: 50px}
        .pt60{padding-top: 60px}
        .pt70{padding-top: 70px}
        .pt80{padding-top: 80px}
        .pt90{padding-top: 90px}
        .pt100{padding-top: 100px}
        .pt200{padding-top: 200px}

        .pb10{padding-bottom: 10px}
        .pb20{padding-bottom: 20px}
        .pb30{padding-bottom: 30px}
        .pb40{padding-bottom: 40px}
        .pb50{padding-bottom: 50px}
        .pb60{padding-bottom: 60px}
        .pb70{padding-bottom: 70px}
        .pb80{padding-bottom: 80px}
        .pb90{padding-bottom: 90px}
        .pb100{padding-bottom: 100px}
        .pb200{padding-bottom: 200px}

        .pl0{padding-left: 0}
        .pl10{padding-left: 10px}
        .pl20{padding-left: 20px}
        .pl30{padding-left: 30px}
        .pl40{padding-left: 40px}
        .pl50{padding-left: 50px}
        .pl60{padding-left: 60px}
        .pl70{padding-left: 70px}
        .pl80{padding-left: 80px}
        .pl90{padding-left: 90px}
        .pl100{padding-left: 100px}
        .pl200{padding-left: 200px}

        .pr0{padding-right: 0}
        .pr10{padding-right: 10px}
        .pr20{padding-right: 20px}
        .pr30{padding-right: 30px}
        .pr40{padding-right: 40px}
        .pr50{padding-right: 50px}
        .pr60{padding-right: 60px}
        .pr70{padding-right: 70px}
        .pr80{padding-right: 80px}
        .pr90{padding-right: 90px}
        .pr100{padding-right: 100px}
        .pr200{padding-right: 200px}

        .m5{margin: 5px}

        .mb0{margin-bottom: 0!important}
        .mb3{margin-bottom: 3px}
        .mb5{margin-bottom: 5px}
        .mb10{margin-bottom: 10px}
        .mb15{margin-bottom: 15px}
        .mb20{margin-bottom: 20px}
        .mb30{margin-bottom: 30px}
        .mb40{margin-bottom: 40px}
        .mb50{margin-bottom: 50px}
        .mb60{margin-bottom: 60px}
        .mb70{margin-bottom: 70px}
        .mb80{margin-bottom: 80px}
        .mb90{margin-bottom: 90px}
        .mb100{margin-bottom: 100px}
        .mb110{margin-bottom: 110px}
        .mb120{margin-bottom: 120px}

        .mt0{margin-top: 0!important}
        .mt3{margin-top: 3px}
        .mt5{margin-top: 5px}
        .mt10{margin-top: 20px}
        .mt20{margin-top: 20px}
        .mt30{margin-top: 30px}
        .mt40{margin-top: 40px}
        .mt50{margin-top: 50px}
        .mt60{margin-top: 60px}
        .mt70{margin-top: 70px}
        .mt80{margin-top: 80px}

        .ml5{margin-left: 5px}
        .ml8{margin-left: 8px}
        .ml10{margin-left: 10px}
        .ml20{margin-left: 20px}
        .ml30{margin-left: 30px}
        .ml40{margin-left: 40px}
        .ml50{margin-left: 50px}
        .ml60{margin-left: 60px}
        .ml70{margin-left: 70px}


        .mr5{margin-right: 5px}
        .mr8{margin-right: 8px}
        .mr10{margin-right: 10px}
        .mr15{margin-right: 15px}
        .mr20{margin-right: 20px}
        .mr30{margin-right: 30px}
        .mr40{margin-right: 40px}
        .mr50{margin-right: 50px}

        .shad{box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;}

        .m0a{margin:0 auto}

        .br8{border-radius:8px}
        .br10{border-radius:10px}
        .br15{border-radius:15px}
        .br20{border-radius:20px}
        .br30{border-radius:30px}
        .br40{border-radius:40px}
        .br50{border-radius:50px}

        .ta-center{text-align: center}
        .ta-end{text-align: end}
        .ta-start{text-align: start}

        .ai-start{align-items: start}
        .ai-center{align-items: center}
        .ac-center{align-content: center}

        .jc-start{justify-content:flex-start}
        .jc-end{justify-content:flex-end}
        .jc-between{justify-content:space-between}
        .jc-around{justify-content:space-around}
        .jc-center{justify-content:center}

        .d-block{display:block}
        .d-in-block{display:inline-block}
        .d-none{display:none!important}

        .col-1{width:10%}
        .col-2{width:20%}
        .col-22{width:22%}
        .col-25{width:25%}
        .col-28{width:28%}
        .col-3{width:30%}
        .col-32{width:32%}
        .col-38{width:38%}
        .col-48{width: 48%}
        .col-4{width:40%}
        .col-45{width:45%}
        .col-5{width:50%}
        .col-55{width:55%}
        .col-58{width:58%}
        .col-59{width:59%}
        .col-65{width:65%}
        .col-6{width:60%}
        .col-68{width:68%}
        .col-7{width:70%}
        .col-78{width:78%}
        .col-8{width:80%}
        .col-9{width:90%}
        .col-10{width:100%}

        .row-10{height: 100%;}

        @media (max-width : 960px) {

        header, footer, .c, .col-1, .col-25, .col-2, .col-3, .col-4, .col-48, .col-45, .col-5,col-58 ,col-55 , .col-6, .col-7, .col-78, .col-8, .col-9, .col-10{width: 100%;display: block}

        .grid4{grid-template-columns: repeat(2, calc( 50% - 15px ) [col-start])}
        .grid5,.grid6{grid-template-columns: repeat(3, calc( calc(100% / 3) - 15px )[col-start])}

        }

        @media (max-width : 620px) {

        .grid2,.grid3,.grid4,.grid5,.grid6{grid-template-columns: repeat(1, calc( 100% ) [col-start]);}

        }


        /* ---------- Custom Style -------------*/
        body{color: #2F2E42}
        header nav li a{color: #2F2E42}
        header nav li a:hover{color: #ef4770}
        header nav ul ul{background-color:#ef4770}
        header nav ul ul a{color:#2F2E42}
        header nav ul ul a:hover{color:#4337ca}
        .head form input[type=search]::placeholder {color: #2F2E42;opacity: 1}
        .head form input[type=search]:-ms-input-placeholder {color: #2F2E42}
        .head form input[type=search]::-ms-input-placeholder {color: #2F2E42}
        .btn1::after{background-color:#ffffff}
        .articles:not(.selections) .article .over .btn1::after{background-color:#2F2E42}
        .btn1:hover::after{background-color:#4337ca}
        .c5 .col-3 div,.cat_head div{background-color:#fca31180}

        .packs .pack:nth-child(2n){background-color:#ef477030}
        .packs .pack:nth-child(2n) .c{flex-direction: row-reverse}

        .dashboard .cont .nav{background-color: #f4f4f4;}
        .dashboard .cont .nav .active{background-color: #ffffff;}
       

        /* ------- Scroll Anim ---------- */

        .reveal-loaded .fade{
        opacity: 0;
        transform: translateY(50px);
        transition: .8s ease;
        }

        .reveal-loaded .fade.anim_left{
        transform: translateX(50px);
        }

        .reveal-loaded .fade.anim_circle{
        transform: scale(0.1);
        }

        .reveal-loaded .fade_in{
        opacity: 1;
        transform: translateY(0)
        }

        .reveal-loaded .anim_left.fade_in{
        transform: translateX(0);
        }

        .reveal-loaded .anim_circle.fade_in{
        transform: scale(1);
        }
