
@media (max-width : 960px) {
    
    .c,.cc, .content .box{width: 100%;padding: 0}

    .content{display: block;padding: 0;}

    span.menu-res{display: none}
    .cont,aside{width: 100%}

    .flex{display: block}
    .content .c{display: block;padding: 0 10px}

    header{width: 100%;padding:13px 8px}
    header .logo{height: 25px;}
    header .c.flex{display: flex;justify-content: start;}
    header .char{margin-left: 30px;}

    header .flex-end{justify-content: flex-start;}
    header .btn2{margin-left: 0;display: block;padding: 8px;}

    span.menu-res{display: block;width: 50px;height: 50px;margin-top: 11px;position: absolute;top: -3px;right: 6px;background-color: #fff;transition: 0.15s linear; text-align: center;border-radius: 15px;}
    .mn .menu-res{right: 10px;z-index: 9100;}
    .mn nav{right:0;z-index: 9000;background-color: #fff;}
    header nav{position: fixed;overflow:hidden;overflow-y:auto;height:100%;right:-80%;top: 0;width: 80% !important;background-color: #fff;box-shadow: 0 0 6px rgba(0,0,0,0.6);transition: 0.15s linear;}
    header nav ul, .head ul{display: block;padding: 0;margin-top: 60px;}
    header nav ul li{display: block;border-bottom: 1px solid #eee;padding: 10px;}
    header nav ul li:hover{background: transparent}
    header nav ul li a{padding: 10px;display:block;color:#454546}
    header nav ul li a span.icon2{display: none}
    header nav ul li ul{position: relative;margin-left: 20px;box-shadow: none;left: 0;display: block;padding: 0;background-color: transparent !important;}
    header nav ul li ul li{border-bottom: none}
    header nav li ul h3,header nav li ul .top{display: none}
    header nav li ul .mn,header nav li ul li{width: 100%;margin-right: 5px}
    header nav ul li ul li a{color:#6a6a70}

    .btn-contact{display: none}

    .caroussel{text-align: center;height: unset;}
    .caroussel .masq{width: 100%;height: 100%;position: relative;}
    .caroussel .masq div{width: 100%}
    .caroussel h1{font-size: 1.3em;}

    .caroussel .slider{height: 370px}
    .caroussel .child img{height: auto;height: 300px;}

    .caroussel .gla_slider .next_btn{right: 15px;}
    .caroussel .previos_btn{right: 65px;}

    .srch{left: 0;right: 0;position: relative;margin-top: 150px;}
    .col-38{width: 100%;}

    .srch .col-38, .srch .col-25{border: 1px solid rgba(0,0,0,0.1);border-radius: 10px;margin-bottom: 10px;padding: 4px 0}
    .srch input{border: 1px solid rgba(0,0,0,0.1);border-radius: 10px;padding: 10px;margin: 10px;width: calc(100% - 20px);}

    .calendar{top: 210px;right: unset;}

    .fz4{font-size: 2em;}
    
    .c2{padding: 20px;}
    .c2 img{object-fit: contain;height: auto;width: 100%;}

    .c3{width: calc(100% - 20px);margin: 0 10px;padding-bottom: 20px;}
    .c3 h2{font-size: 2.3em;padding: 20px;}
    .c3 p{padding: 10px;}
    
    .c4{margin-top: 40px;padding: 10px;}

    .articles .article img{height: 200px;}

    select{height: 46px;background-color: #fff;}

    /* .contact .col-5{margin-bottom: 20px} */

    /* .page .service{margin-bottom: 30px}
    .page h1{font-size: 1.5em;margin-bottom: 30px!important} */

    /* .gla-form .group{display: block}
    .gla-form .group input[type=text], .gla-form .group input[type=email]{width: 100%}
    .gla-cptch{margin: 20px 0} */

    footer{padding: 30px;}
    footer img{margin-bottom: 30px}
    footer .child{width: 100%}
    footer .c{display: block;}
    footer .c>div{margin-bottom: 40px;width: 100%}
    footer .c>div a{margin-bottom: 20px;}

    header .menu ul{width: 80%;right: -85%;padding-top: 20px;box-shadow: 0 0 5px rgba(0,0,0,0.3);font-size: 1.2em;position: fixed;bottom: 0px;border-radius: 0;top: 0;transition: 0.2s ease;display: block;}
    header .menu:hover ul{right: -30px}

    .single{padding: 10px;}
    .single .images .img:first-child{height: 270px}
    .single .images .img{height: 130px;}

    .login .side{display: none;}

    .dashboard .side{width: 100%;padding: 20px;height: 115px;overflow: hidden;}

    .dashboard .cont{padding: 0px;margin: 10px 0 30px;overflow: hidden;width: 100%;overflow-x: auto;}
    .dashboard .cont .nav li a{padding: 8px;width: 102px;}

    h2{font-size: 1.3em;}

    .btn{padding: 8px;}

    .m_flex{display: flex;}

    .m_p10{padding: 10px;}
    .m_m20{padding: 20px;}

    .m_ml0{margin-left: 0;}
    .m_ml10{margin-left: 10px;}
    .m_mb0{margin-bottom: 0px;}
    .m_mb10{margin-bottom: 10px;}
    .m_m10{margin: 10px;}
    .m_mb20{margin-bottom: 20px;}
    .m_mt20{margin-top: 20px;}

    .grid4, .m_grid2{display:grid;grid-template-columns: repeat(2, calc( 50% - 7px ) [col-start]);grid-gap:10px}
    .m_grid3{display:grid;grid-template-columns: repeat(3, calc( calc(100% / 3) - 8px) [col-start]);grid-gap:10px}

}