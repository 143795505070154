@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700');

@font-face {
    font-family: 'icons';
    src: url('icons.ttf') format('truetype')
}

*{margin:0;box-sizing: border-box}
body{font-family: 'Montserrat', sans-serif}

#root{height: 100vh;}

header{z-index: 9000;padding: 0 20px;}
form input::placeholder{color: #000;font-family: 'Montserrat', sans-serif;}
button{font-family: 'Montserrat', sans-serif;}
.flex{display: flex;justify-content: space-between}
.c{margin: 0 auto;width:1300px}
.cc{margin: 0 auto;width:1100px}

.logo{height: 40px; margin: 5px 0 !important}

.head .icon{text-transform: none;margin-right: 7px}
.head form{padding: 5px}
.head form input{padding: 0;border: none;background-color: transparent}
.head form input[type=submit]{margin-right: 0!important;font-size: 1.2em;cursor: pointer;}
.head .act{margin-right: 18px;position: relative;}
.head .act:last-child{margin-right: 0;}
.head .act .icon{font-size: 1.4em!important;}
.head .notif{position: absolute;font-size: .7em;width: 16px;height: 16px;border-radius: 8px;text-align: center;left: 13px;top:-4px}

.head ul{display: flex}
.head ul li a{color:#000;padding: 25px 20px;;display: inline-block;}
.head ul ul{position: absolute;top: 100%;padding: 15px;display: none;min-width: 200px;}
.head nav{margin-bottom: -1px;}
.head nav ul{padding:0;justify-content: center;}
.head nav ul ul li a{padding: 15px 20px;    }
.head ul li:hover ul{display: block;}

header .avatar{width: 40px;height: 40px;border-radius: 50px;margin: 0 10px;object-fit: cover}
header .menu{position: relative;padding: 0;}
header .menu ul{position: absolute;top: 100%;right: 0;padding: 10px;min-width: 250px;display: none;border: 1px solid var(--cl5);z-index: 9999;background-color: #fff;border-radius: 15px;}
header .menu li li a{padding: 5px;display: block;font-size: 0.9em;}
header .menu li li a:hover{color: var(--cl4);}
header .menu li:hover ul{display: block;}
header .menu .logout{text-decoration: underline; font-size: .9em; margin: 15px 5px;display: block;cursor: pointer;}

.head-login{position: absolute;top: 0;}

.trait{border-right: 2px solid #999;margin-right: 0px;margin-left: -20px;}

.shape1{position: absolute; top: 150px; right: 40px;-webkit-animation: effect2 45s linear infinite;}
.shape2{position: absolute; bottom: 50px; left: 100px;-webkit-animation: effect1 20s ease-out infinite;}
.shape3{position: absolute; top: 0; right: 0;-webkit-animation: effect1 40s ease-out infinite;}
.shape4{position: absolute; bottom: 0; left: 0;-webkit-animation: effect1 60s ease-out infinite;}

.caroussel{width: 100%;height: 800px;z-index: 6000;position: relative;}
.caroussel .slider{transition: 0.4s ease;display: flex; width: 100%;height: 600px;}
.caroussel .child{position:relative;display: flex;justify-content:center;flex-wrap: wrap;}
.caroussel .child img{position:absolute;width:100%;height:100%;object-fit: cover;border-radius: 20px;}
.caroussel .child div{position:relative;color: #fff;}
.caroussel h1{margin: 0 auto;font-weight:500;;margin-bottom: 20px;display: block}

.srch{position: absolute;bottom: 100px;right: 200px;left:0;}
.srch form{position: relative;}
.srch input, .srch .input{padding: 5px 10px;border: none;width: 100%;font-size: 1.2em;display: block;}
.srch form input::placeholder{width: 90%;font-weight: 500;}
.srch form p{font-size: .8em;padding: 0 10px;}
.srch form .flex div{width: 90%;}
.srch label, .srch .label{width: 100%;height: 100%;display: flex; flex-direction: column;justify-content: center;}
.srch .icon{margin: 10px 0;} 
.srch button{cursor: pointer;font-size: 1.7em;} 
.calendar{position: absolute;top: 87px; right: 100px;z-index: 9999;}
.calendar .rdrDefinedRangesWrapper{display: none;}
.calendar .rdrMonthsVertical{flex-direction: row;}

.search .srch{position: initial;}
.search .srch form{border: 1px solid #f5f4f4;position: relative;}

.filter .gla-form input:not(.btn), .filter .gla-form textarea, .filter .gla-form select{padding: 10px!important; border-radius: 15px!important;}

.brcText{-webkit-text-stroke: 5px #000;}

.masq{position: absolute;width: 100%;height: 600px;left: 0;top:50px;bottom:0;}

.caroussel .icon{cursor: pointer;font-size: 1.7em;}
.caroussel .shape{position: absolute;bottom: 0;height: 100%;right: 0;}
.car-btns{position: absolute;bottom: 20px;right: 20px;}
.car-btns span{border-radius: 8px;}
.caroussel .wave{position: absolute;bottom: -5px;left: 0;right: 0;}
.caroussel .wave img{width: 100%;}

.btn{padding: 20px 25px;font-weight: 500;cursor: pointer;border-radius: 60px;}
.btn2{padding: 15px 20px;font-weight: 400;cursor: pointer;border-radius: 60px;}
.btn .icon, .btn.icon{text-transform: none;}
.action.icon{width: 30px; height: 30px;border-radius: 15px;cursor: pointer;}

.ovh{overflow: hidden;}

#categories .category{border-radius: 15px;background-color: #fff; overflow: hidden;}
#categories .category img{width: 100%;height: 250px;border-radius: 15px;object-fit: cover;}
#categories .category .cnt{padding: 15px 0;}
#categories .category .cnt h3{font-weight: 500;margin-bottom: 10px;}
#categories .category .cnt p{font-size: .9em;color: #7d7f8e;}

.c2 div{text-align: center;padding: 20px;}
.c2 img{height: 130px;}
.c2 span{display: block;padding: 15px 30px;}

.villes .ville{border-radius: 15px;border-style: solid;border-width: 1px;display: flex;align-items: center;padding: 15px;}
.villes .ville img{width: 50px; height: 50px;border-radius: 15px;object-fit: cover;}
.villes .ville .cnt{padding: 10px;}
.villes .ville .cnt .t{font-size: 1.1em;font-weight: 500;display: block;color: #000;}

.articles .article{border-radius: 20px;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;overflow: hidden;}
.articles .article img{width: 100%;height: 300px;object-fit: cover;}
.articles .article .cnt{padding: 15px;margin-bottom: 10px;}
.articles .article .cnt .t{font-size: 1.1em;font-weight: 500;display: block;}
.articles .article .place{border-bottom: 1px solid #F3F4F6;padding-bottom: 10px;}
.place{display: flex;align-items: center;font-size: .9em; color: #7d7f8e;}
.place .icon{font-size: 1.4em;}
.place .icon{margin-right: 5px;}
.articles .article .infos{display: flex;justify-content: space-between;align-items: center;padding-top:12px;}
.articles .article .infos .p{font-weight: 500;}
.price{font-weight: 500;}
.rating span:not(.p), .price .p{color: #7d7f8e;}
.rating .icon{color: #fca311!important;}
/* 
.search .listings{height:100%}
.search .list{padding: 0 20px;}
.search .articles{padding-bottom: 30px;}
.search .map{position: sticky; top:0;height: calc(100% - 206px);border-radius: 15px 0 0 15px;overflow: hidden;}
.search .map iframe{width: 100%;height: 100vh}
.search .articles .article img{height: 230px;} */

.button{border: 1px solid #f5f4f4;border-radius: 10px;padding: 10px 15px; margin-right: 20px;font-size: .9em;cursor: pointer;}
.search .listings .button:last-child{margin-right: 0;}
.button .icon:first-child{margin-right: 5px;}
.button .icon:last-child{margin-left: 5px;}

.divider{border-top: 1px solid #F3F4F6;text-align: center;}
.divider::after{content: "ou";background-color: #fff;margin-top: -17px;display: inline-block;padding: 5px 10px;color: #666;}

table{border-collapse: collapse;border-radius: 8px;overflow: auto}
table{margin:30px 0;color:#000;border-collapse: collapse; border:0}
table{border-spacing: 1;border-collapse: collapse; background:white;border-radius:6px;max-width:1200px; width:100%;margin:0 auto;border: 1px solid #40bd6e40;}
table tr{border-bottom:1px solid #40bd6e40!important}
table th{width:20%!important; padding: 15px;background-color: #40bd6e40!important;font-weight: 700;font-size: 1.1em;text-transform: uppercase; }
table td{padding: 15px;text-align: left;vertical-align:middle;font-weight: 300;font-size: .9em;}

.more-img img{width:100%;height: 330px;object-fit: cover;cursor: pointer; transition: 2s ease;}
.more-img img:hover{transform: scale(110%);}
.more_services a{display: block;margin-top: 10px; font-size: 1.2em;}

.content{padding: 30px 0; border-top: 1px solid #F3F4F6;}

.single .c-infos .price{font-size: 2em;}
.single .images{position: relative;}
.single .images .img{height: 260px;}
.single .images .img img{object-fit: cover;width: 100%;height: 100%;border-radius: 10px;}
.single .images .img:first-child{grid-column: 1 / 3;grid-row: 1 / 3;height: 540px;}
.single .cont{padding: 30px 0;}
.single .map{height: 200px;border-radius: 15px;overflow: hidden;}
.single .map iframe{width: 100%;height: 100%}
.single .side{position: sticky;top:20px}

.avatar_img{border-radius: 15px;width: 160px;height: 160px;object-fit: cover;}

.car div{display: flex;justify-content: start; align-items: center;}
.car .icon{margin-right: 10px; font-size: 1.5em;}

.book{padding: 20px;border-radius: 15px;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;border: 1px solid #f5f4f4;}
.book form > div{border-radius: 10px;border: 1px solid #F3F4F6;margin-bottom: 20px;padding: 5px 20px;}
.book form input{width: 100%;padding: 12px;border:none; font-size: 1em;}
.book form .icon{font-size: 1.1em;}
.book .calendar{top: 0; right: 0}

.tag{border-radius: 20px; padding: 10px 20px;display: inline-block;margin-right: 20px;margin-bottom: 20px;}
.tag .icon{margin-right: 8px;}

.comment{display: flex; padding: 15px;border-radius: 15px;margin-bottom: 20px;}
.comment .av{border-radius: 15px;width: 60px;height: 60px;background-color: #F3F4F6;overflow: hidden;margin-right: 10px;}
.comment .av img{width: 100%;height: 100%;object-fit: cover;}
.comment .n{font-weight: 500;}
.comment .d{font-size: .8em;}
.comment .rating{margin: 8px 0;}

.paragraph p{margin-bottom: 10px;}
.paragraph ul{margin-bottom: 20px;}
.paragraph li{margin-bottom: 10px;}

.rent-car .b1{margin-top: -140px;text-align: center;}
.rent-car .b2 img{width: 100%;object-fit: contain;}

.steps .step{margin-bottom: 35px;}
.steps .step div{width: calc(100% - 80px);}
.steps .step .num{width: 50px;height: 50px;border-radius: 50%;padding: 13px 5px;text-align: center;font-size: 1.2em;font-weight: 600;color: #fff;}
.steps .step div span{font-weight: 500;font-size: 1.2em;display: block;margin-bottom: 15px;}

.dashboard .side{padding: 30px;border-radius: 20px;}
.dashboard .side ul{padding: 0;}
.dashboard .side ul li{list-style: none;margin-bottom: 15px;}
.dashboard .side ul li .icon{margin-right: 20px;}
.dashboard .cont{padding: 15px 30px;}
.dashboard .cont .nav{padding: 5px; display: inline-flex;border-radius: 10px;}
.dashboard .cont .nav li{margin-right: 20px;border-radius: 10px;list-style: none;}
.dashboard .cont .nav li a{padding: 12px 20px;display: block;}

.min-box{border-radius: 20px; padding: 60px 40px; }
.min-box span{font-weight: 600;display: block;margin-bottom: 15px;}

.check{content: "&#xe080"; }

.contact-form{box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;}

.signatureView{background-color: #fff;border: 1px solid var(--cl5);width: 100%;height: 400px;}
.signature{width: 100%;height: 400px;}

aside ul{padding: 0;}
aside ul li{list-style: none;font-weight: 500;}

footer{color: #b5b5b8;font-size: .9em}
footer .title{font-size: 1em; font-weight: 600;text-transform: uppercase;display:block;margin-bottom: 15px}
footer a{display: block;margin-bottom: 8px;color: #b5b5b8}
footer .info p{margin-bottom: 8px}
.facebook{background-color: #4267B2}
.twitter{background-color: #1DA1F2}
.instagram{background-color: #e95950}
.kiuper{background-color: #6F9C00}

.contact p{margin-bottom: 10px;}

.gla-pagination a,.gla-pagination .btn{padding: 8px 15px;margin-right: 5px}
.gla-pagination{text-align: right}

.more-articles{position: relative;}
.more-articles .article{margin-right: 10px;width: 100%;}
.more-articles h2{margin: 30px 0 15px 0}

.car-slide{overflow: hidden;margin: 40px 0;position: relative;}
.car-slide .slide{display: flex;justify-content: flex-start;transition: 0.1s linear;padding: 0 20px;}
.car-slide .article{margin-right: 20px}
.btn.sld{position: absolute;top: 50%;padding:12px 15px;text-align: center;}
.btn.sld#nx{right: 0;cursor: pointer}
.btn.sld#pr{transform: rotate(180deg)}

.messages_side{height: calc(100vh - 135px);overflow: hidden;overflow-y: auto;}
.messages_side .badge, header .badge{background: red;padding: 2px 5px !important;color: #fff;border-radius: 30px;font-size: 0.75em !important;font-family: 'Montserrat';}
header .badge{font-size: 0.4em !important}
.messages_box{padding-left: 20px}
.messages{padding: 20px;border-radius: 20px;height: calc(100vh - 210px);overflow: hidden;overflow-y: auto;}

.message{display: block;margin-bottom: 10px;}
.message p{background-color: rgb(39, 119, 205);width: auto;max-width: 80%;display: inline-block;color:#fff;padding: 6px;border-radius: 5px;}
.message time{font-size: 0.8em;display: block;margin-top: 4px;}

.message.me{text-align: right;}
.message.me p{background-color: #b9babf;color: #333;}

.messages_send{padding: 10px;margin-top: 20px;border-radius: 20px;}
.messages_send input[type=text]{border: none;width: 90%;}
.messages_send input[type=submit]{width: calc(10% - 5px);display: block;padding: 10px;border-radius: 5px;text-align: center;margin-left: 5px;cursor: pointer;border: none;}

.user_card{display: flex;align-items: center;font-size: 0.8em;padding: 8px;border-radius: 20px;background-color: #fff;box-shadow: 0 0 3px rgba(0,0,0,0.05   );margin-bottom: 10px;}
.user_card img{width: 60px;height: 60px;object-fit: cover;border-radius: 14px;margin-right: 10px;}
.user_card img.img_50{width: 50px;height: 50px}
.user_card h3{display: block;margin-bottom: 10px;}

textarea:focus, input:focus{outline: none}
.gla-form input:not(.btn),.gla-form textarea,.gla-form select{padding: 15px;border-radius: 50px;border: 1px solid #d2d2d3;width: 100%;margin-bottom: 25px;}
.gla-form label{font-size: .9em; font-weight: 500;margin-bottom: 10px;display: block;}
.gla-form input[type=submit]{width: auto;margin-top: 15px; padding: 15px 30px;}
.gla-form textarea{height: 80px;font-family: 'Montserrat', sans-serif;border-radius: 25px;}
.gla-cptch input{width: 60px !important}

.cgu_accept input[type=checkbox]{display: inline-block;width: 20px;}
.cgu_accept p{display: inline}

.btn_social_connect a{border-radius: 50px;padding: 10px 30px; text-align: center;color: #000;}
.btn_social_connect .icon{margin-right: 8px; }

.mask{position: fixed;top: 0;bottom: 0;left: 0;right: 0;background: rgba(0,0,0,0.4);display: flex;align-items: center;justify-content: center;padding:20px;z-index: 9999}
.mask img{max-width: 100%;max-height: 100%;background: rgba(255,255,255,0.5);border-radius:3px;box-shadow: 0 0 6px #444;transition: 0.14s linear}
.mask .exit, .mask .btn{position: absolute;text-align: center;line-height: 40px;font-size: 2em;cursor: pointer;opacity: 0.7;padding: 10px 20px}
.mask .exit{right: 10px;top: 10px !important;border-radius: 50px;}
.mask .btn{top: 48%;transition: 0.14s linear;background: transparent;color: #0a1d61;vertical-align: middle}
.mask .btn:hover, .mask .exit:hover{border: 1px solid #0a1d61}
.mask .btn-next{right: 20px;}
.mask .btn-prev{left: 20px}
.mask .btn-next:hover,.mask .btn-prev:hover,.mask .exit:hover{opacity: 1}

.succes, .error{display:block;margin: 20px 0;padding:10px;color: #fff;border-radius: 15px;}
.succes{background: #4caf50}
.error{background: #ef5350}

.stt_1_3{color: #4caf50}
.stt_2{color: #ef5350}
.stt_4{color: #5053ef}

.img_thumb{height: 100px;border-radius: 10px;object-fit: cover;}

.rem{font-size: 0.9em !important;color: #666;font-style: italic}
a{text-decoration: none;}
footer li,header li,.contact li{list-style: none;margin-left: 0}
footer a{color: #333;}

span.menu-res{display: none; padding: 10px;position: absolute;top: 20px;right: 20px;transition: 0.15s linear; cursor: pointer;}


.icon{font-family: 'icons' !important;line-height: 1;vertical-align: middle;margin-right: 5px;}


.loader {display: inline-block;width: 80px;height: 80px;}
  
.loader:after {content: " ";display: block;width: 64px;height: 64px;margin: 8px;border-radius: 50%;border: 6px solid #4337ca;border-color: #4337ca transparent #4337ca transparent;animation: lds-dual-ring 1.2s linear infinite}

.modalContainer {position: fixed;width: 100vw;height: 100%;display: flex;top: 0;bottom: 0;left: 0;right: 0;justify-content: center;align-items: center;background-color: rgba(0, 0, 0, 0.4);}
.modal{width: 90%;max-width: 500px;border: 5px;padding: 10px;background-color: #fff;box-sizing: border-box;padding: 20px;border-radius: 15px;}
@keyframes lds-dual-ring {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}}

/* Switch ---------- */

.switch {
    position: relative;
    display: inline-block;
    width: 57px;
    height: 26px;
  }
  
  .switch input { 
    opacity: 1;
    width: 57px;
    height: 26px;
  }
  
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .switch .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  .switch .slider:after {
    position: absolute;
    content: "Pro";
    right: 6px;
    bottom: 6px;
    -webkit-transition: .4s;
    transition: .4s;
    font-size: .8em;
    color: #fff;
    font-weight: 500;
  }
  
  .switch input:checked + .slider {
    background-color:var(--cl4);
  }
  
  .switch input:checked + .slider:before {
    -webkit-transform: translateX(29px);
    -ms-transform: translateX(29px);
    transform: translateX(29px);
  }

  .switch input:checked + .slider:after {
    -webkit-transform: translateX(-24px);
    -ms-transform: translateX(-24px);
    transform: translateX(-24px);
  }

/* Google Autocomplte ---------- */
.pac-container{z-index: 9999!important;border-radius: 10px!important;font-family: 'Montserrat', sans-serif!important;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px!important;border-top: 1px solid #fff!important;}
.pac-item{padding: 7px 10px!important;font-size: .9em ;border-top: 1px solid var(--cl5);}
.pac-item:first-child{border-top: 0!important;}
.pac-logo::after{display: none!important;}
/* GLA Slider ---------- */

.gla_slider{position: relative;overflow: hidden;}
.gla_slider .gla_slider_slider{width:100%;display: flex;transition: 0.4s ease;flex-wrap: nowrap}

.gla_slider .slider_btn{position: absolute;color: #000;border: 3px solid #000;bottom:0;padding: 3px;border-radius: 40px;cursor: pointer;transition: 0.2s ease;font-size: 1.5em;}
.gla_slider .slider_btn:hover{opacity: 1;}
.gla_slider .next_btn{right: 15px; }
.gla_slider .next_btn:before{content: "\ea6e";}
.gla_slider .previos_btn{right: 60px}
.gla_slider .previos_btn:before{content: "\ea64";}

.caroussel .gla_slider .slider_btn{padding: 6px;}
.caroussel .gla_slider .next_btn{right: 0; }
.caroussel .slider_btn{bottom: 0;top: auto}
.caroussel .previos_btn{left: auto; right: 50px;}



/* GLA Alert ---------- */

.mask{display: flex;align-items: center;justify-content: center;}

.gla_alert{width: 400px;background: #fff;box-shadow: 0 0 20px rgba(0,0,0,0.3);}
.gla_alert p{padding: 20px;}
.gla_alert .gla_btns{padding: 10px;background: rgba(0,0,0,0.04);border-top: 1px solid rgba(0,0,0,0.1);display: flex;justify-content: flex-end;}
.gla_alert .gla_btns span{margin-right: 20px;font-weight: bold;text-transform: uppercase;cursor: pointer;}


/* GLA Captcha ---------- */

.gla_captcha{background: #eee;border:1px solid rgb(223, 222, 222);border-radius: 8px;padding: 10px;}
.gla_captcha h3{font-weight: normal;margin-bottom: 10px;}
.gla_captcha span{background: #fff;padding: 5px;color: #333;width: 30px;text-align: center;cursor: pointer;opacity: 0.7;border-radius: 8px;}
.gla_captcha span:hover{opacity: 1;}
.gla_captcha input{width: 100px;height: 30px;}

.ri-instagram:before { content: "\ee66"; }
.ri-facebook:before { content: "\ecbb"; }
.ri-facebook-fill:before { content: "\ecbc"; }
.ri-google-fill:before { content: "\edd4"; }
.ri-map:before { content: "\ef0a"; }
.ri-calendar:before { content: "\eb25"; }
.ri-arrow-right:before { content: "\ea6e"; }
.ri-arrow-left:before { content: "\ea64"; }
.ri-arrow-down:before { content: "\ea4e"; }
.ri-search:before { content: "\f0d1"; }
.ri-star-fill:before { content: "\f186"; }
.ri-heart-fill:before { content: "\ee0a"; }
.ri-user:before { content: "\f264"; }
.ri-lock:before { content: "\eece"; }
.ri-shield-check-fill:before { content: "\f0ff"; }
.ri-shield-check-line:before { content: "\f100"; }
.ri-price:before { content: "\f025"; }
.ri-car-line:before { content: "\eb39"; }
.ri-equalizer:before { content: "\ec9d"; }
.ri-user-4:before { content: "\f258"; }
.ri-gas-station:before { content: "\edad"; }
.ri-suitcase-3:before { content: "\f1b7"; }
.ri-apps-2-line:before { content: "\ea42"; }
.ri-swap-box-line:before { content: "\f1c9"; }
.ri-flashlight-line:before { content: "\ed3d"; }
.ri-calendar-line:before { content: "\eb27"; }
.ri-route-line:before { content: "\f09b"; }
.ri-bookmark-line:before { content: "\eae5"; }
.ri-checkbox-circle-fill:before { content: "\eb80"; }
.ri-mail-line:before { content: "\eef6"; }
.ri-share-line:before { content: "\f0fe"; }
.ri-checkbox-fill:before { content: "\eb82"; }
.ri-map-pin-2-fill:before { content: "\ef09"; }
.ri-bank-card-fill:before { content: "\ea91"; }
.ri-close-line:before { content: "\eb99"; }
.ri-check-line:before { content: "\eb7b"; }
.ri-message-2-line:before { content: "\ef44"; }